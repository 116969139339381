import React, { useEffect, Fragment } from "react"
import BelowFoldCards from "../../general/belowFoldCards"
import CTA from "../../general/cta"
import BadgesEntryFold from "../../general/badgesEntryFold"
import FundCash from "../../../../../assets/img/fund-with-cash.inline.svg"
import PayIcon from "../../../../../assets/img/pay-icon.inline.svg"
import NairaIcon from "../../../../../assets/img/naira.inline.svg"
import ImageTextLeft from "../../general/imageTextLeft"
import ImageTextRight from "../../general/imageTextRight"
import CardlessPaymentIllustration from "../../../../../assets/img/cardless-payments.inline.svg"
import ThirdIllustration from "../../../../../assets/img/send-money.inline.svg"
import FirstIllustration from "../../../../../assets/img/cardless-payUSSD-illustration.inline.svg"
import SecondIllustration from "../../../../../assets/img/cardless-payId-illustration.inline.svg"
import Card1 from "../../../../../assets/img/blur/kuda-new-card-blur.inline.svg"
import { scrollToElement } from "../../../../utility/utils"

import FAQList from "../../general/faqsList";
import MoreForYouComponent from "../../general/more-for-you"

import TransferIcon from '../../../../../assets/img/more-for-you/kuda-transfer.inline.svg'
import KudaCardIcon from '../../../../../assets/img/more-for-you/kuda-cards-icon.inline.svg'
import BorrowIcon from '../../../../../assets/img/more-for-you/kuda-borrow-icon.inline.svg'

const entryContent = {
  title: "Choose Kuda and leave cash behind for good.",
  subtitle:
    "Enjoy your cashless life with easy payment options on the Kuda app!",
  illustration: <CardlessPaymentIllustration/>,
}

const kudaTopFeatures = [
  {
    icon: <FundCash />,
    text: "Switch to Pay With USSD for cardless POS payments across Nigeria.",
  },
  {
    icon: <NairaIcon />,
    text: "Use Pay With Bank to make online payments locally without a card.",
  },
  {
    icon: <PayIcon />,
    text: "Get 25 free and fast transfers to all Nigerian banks every month.",
  },
]

const firstSection = {
  payTitle: "You don’t need a card to pay on a POS.",
  subtitle: "Use Pay With USSD on Kuda to make easy cardless POS payments.",
  url: "https://help.kuda.com/support/solutions/articles/73000608396-how-to-pay-with-ussd-cardless-pos-payment-",
  name: "See How Pay With USSD Works",
  payIllustration: <FirstIllustration />,
}

const secondSection = {
  payTitle: "Transform the way you pay online.",
  subtitle: "Forget cards, use Pay With Bank on Kuda for fast and reliable local web payments.",
  url: "https://help.kuda.com/support/solutions/articles/73000560693-pay-online-directly-from-your-kuda-account-pay-with-bank-",
  name: "See How Pay With Bank Works",
  payIllustration: <SecondIllustration />,
}

const thirdSection = {
  payTitle: "Send money for free forever.",
  subtitle: "Make transfers to other Kuda accounts almost instantly at no cost.",
  url: "https://kuda.onelink.me/abUI/344e3dde",
  name: "Download Kuda",
  illustration: <ThirdIllustration />,
}

const questions = [
  {
      heading: "What cashless payment options are on the Kuda app?",
      list: (
          <span className="flex flex-column inApp-contact-ways--wrap">
              <span className="faq-description f-16">
                Free transfers, Pay With Bank, Pay With USSD and cards are the cashless payment options on the Kuda app.
              </span>

          </span>),
      index: 1
  },
  {
      heading: "How many free transfers can I get on the Kuda app?",
      list: (
          <span className="flex flex-column inApp-contact-ways--wrap">
              <span className="faq-description f-16">
              When you sign up on Kuda with your BVN (Bank Verification Number), you’ll get 25 free transfers to all Nigerian banks every month.
              </span>

          </span>),
      index: 2
  },
  {
      heading: "Where does Pay With Bank work?",
      list: (
          <span className="flex flex-column inApp-contact-ways--wrap">
              <span className="faq-description f-16">
              Pay With Bank works on websites and apps that accept payments through Paystack. Learn more about Pay With Bank <a href="https://help.kuda.com/support/solutions/articles/73000560693-pay-online-directly-from-your-kuda-account-pay-with-bank-"  target="_blank" rel="noopener noreferrer"  className="cursor-pointer text-bold color-primary">here</a>.
              </span>

          </span>),
      index: 3
  },
  {
      heading: "Where does Pay With USSD work?",
      list: (
          <span className="flex flex-column inApp-contact-ways--wrap">
              <span className="faq-description f-16">
              Pay With USSD works on POS terminals and on websites that accept payments through Paystack, Flutterwave or Remita. Learn more about Pay With USSD <a href="https://help.kuda.com/support/solutions/articles/73000608396-how-to-pay-with-ussd-cardless-pos-payment-"  target="_blank" rel="noopener noreferrer"  className="cursor-pointer text-bold color-primary">here</a>.
              </span>

          </span>),
      index: 4
  },
  {
      heading: "What kind of card can I get on the Kuda app?",
      list: (
          <span className="flex flex-column inApp-contact-ways--wrap">
              <span className="faq-description f-16">
              You can request a physical Kuda Visa naira card and a virtual Kuda Visa naira card. Learn more about Kuda cards <a className="cursor-pointer text-bold color-primary" href="https://help.kuda.com/support/solutions/folders/73000522092"  target="_blank" rel="noopener noreferrer" >here</a>.
             </span>

          </span>),
      index: 5
  },

]

const moreForYou = [
  {
      icon: <TransferIcon />,
      title: "Transfer & Spend",
      subText: "Send money for free to any Nigerian account with 25 free transfers every month.",
      linkTo: `/en-ng/spend/`
    },
  {
    icon: <KudaCardIcon />,
    title: "Cards",
    subText: "Get a physical or virtual Kuda card for easy online and offline payments.",
    linkTo: `/en-ng/cards/`
  },
  {
    icon: <BorrowIcon />,
    title: "Loans",
    subText: `Get instant loans up to ₦150,000 in the Kuda loan app easily in Nigeria without paperwork.`,
    linkTo: `/en-ng/personal-loan/`
  },

]

const CardlessPayments = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <BadgesEntryFold
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        illustration={entryContent.illustration}
        fallback={<Card1 className="blur isImage" />}
      />
      <BelowFoldCards topFeatures={kudaTopFeatures} />
      <ImageTextRight
        title={firstSection.payTitle}
        subtitle={firstSection.subtitle}
        illustration={firstSection.payIllustration}
        name={firstSection.name}
        url={firstSection.url}
        isExternal={true}
      />
      <ImageTextLeft
        title={secondSection.payTitle}
        subtitle={secondSection.subtitle}
        illustration={secondSection.payIllustration}
        name={secondSection.name}
        url={secondSection.url}
        isExternal={true}
      />
      <ImageTextRight
        title={thirdSection.payTitle}
        subtitle={thirdSection.subtitle}
        illustration={thirdSection.illustration}
        name={thirdSection.name}
        url={thirdSection.url}
        isExternal={true}
      />
      <FAQList maxWidthClass='w-100' title={"Cardless Payments FAQs"} questions={questions} />
      <MoreForYouComponent moreForYou={moreForYou} />
      <CTA />
    </Fragment>
  )
}

export default CardlessPayments
